export const types = {
  DEFAULT: "DEFAULT",
  ShowAppLoading: "ShowAppLoading",
  HideAppLoading: "HideAppLoading",
  SetSocket: "SetSocket",
  ShowError: "ShowError",
  HideError: "HideError",
  ShowModal: "ShowModal",
  HideModal: "HideModal"
}
